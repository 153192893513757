export const formatId = text => {
  const id = text
    ? text
        .toLowerCase()
        .replace(/[^a-z-0-9\s]+/g, '')
        .trim()
        .replace(/\s+/g, '-')
    : ''

  return id
}
