import React from 'react'
import { formatId } from '../utils/format-id'

const TextBlock = ({ title, lead }) => {
  return (
    <div
      id={formatId(title)}
      className="mt-12 lg:mt-35 mb-20 lg:mb-40 px-3 lg:px-14"
    >
      <div className="lg:flex lg:-mx-2">
        {title && (
          <div className="lg:w-1/2 lg:px-2">
            <h3 className="mb-12 lg:mb-20 lg:w-4/5 text-4xl text-grey font-light leading-tight">
              {title}
            </h3>
          </div>
        )}
        {lead && (
          <div
            className="lg:w-1/2 lg:px-2 rte text-lg leading-lg"
            dangerouslySetInnerHTML={{ __html: lead }}
          />
        )}
      </div>
    </div>
  )
}

export default TextBlock
