import React from 'react'
import { formatId } from '../utils/format-id'

const VideoTextBlock = ({ title, text, videoUrl }) => {
  return (
    <>
      <div
        id={title ? formatId(title) : ''}
        className="lg:mt-35 lg:pb-16 mb-16 lg:mb-40 px-3 lg:px-14"
      >
        <div className="lg:flex lg:-mx-2">
          <div className="lg:w-1/2 lg:px-2 mb-16">
            {title && (
              <h3 className="mb-10 lg:mb-16 lg:w-4/5 text-4xl font-light text-grey leading-tight">
                {title}
              </h3>
            )}
            {text?.html && (
              <div className="rte w-9/10">
                <div
                  dangerouslySetInnerHTML={{
                    __html: text?.html,
                  }}
                />
              </div>
            )}
          </div>
          {videoUrl && (
            <div className="lg:w-1/2 lg:px-2">
              <div className="relative ratio ratio-5/8 lg:mx-0">
                <iframe
                  title={title}
                  className="absolute pin-t pin-l w-full h-full"
                  src={videoUrl}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default VideoTextBlock
