import React from 'react'
import { graphql } from 'gatsby'

import { formatId } from '../utils/format-id'

import Layout from '../components/layout'

import Hero from '../components/hero'
import VideoTextBlock from '../components/VideoWithTextBlock'
import TextBlock from '../components/TextBlock'
import icon from '../images/download-icon.svg'

import SEOPage from '../components/SEO/Page'

class PatientsPage extends React.Component {
  render() {
    const { data, location } = this.props
    const patientContent = data.prismicPatients.data

    var heroImgs = []
    var heroImgsMobile = []

    patientContent.hero.forEach(item => {
      heroImgs.push(item.desktop_image.url)
      heroImgsMobile.push(item.mobile_image.url)
    })

    return (
      <Layout>
        <SEOPage
          title={patientContent.title && patientContent.title}
          location={location}
        />
        <div className="text-lg leading-lg tracking-lg">
          <Hero heroImgs={heroImgs} heroImgsMobile={heroImgsMobile} />

          <div
            id={
              patientContent?.lead_title
                ? formatId(patientContent?.lead_title)
                : ''
            }
            className="mt-12 lg:mt-28 mb-6 px-3 lg:px-14"
          >
            <div className="lg:flex lg:-mx-2">
              {patientContent.lead_title && (
                <div className="lg:w-1/2 lg:px-2">
                  <h1 className="mb-12 lg:mb-20 lg:w-4/5 text-4xl font-light leading-tight text-grey">
                    {patientContent.lead_title}
                  </h1>
                </div>
              )}
              {patientContent.lead_text.html && (
                <div
                  className="lg:w-1/2 lg:px-2 rte text-lg leading-lg"
                  dangerouslySetInnerHTML={{
                    __html: patientContent.lead_text.html,
                  }}
                />
              )}
            </div>
          </div>

          <div className="mt-6 mb-20 lg:mb-40 px-3 lg:px-14">
            <div className="flex flex-col lg:flex-row lg:-mx-2">
              {patientContent.downloads && (
                <div className="lg:w-1/2 lg:px-2 mt-12 lg:mt-0 flex-order-2 lg:flex-order-1">
                  <div className="lg:w-3/4">
                    {patientContent.downloads.map((item, i) => {
                      return (
                        <a
                          key={i}
                          href={item.download_button.url}
                          className="text-charcoal bg-green cursor-pointer outline-none hover:opacity-75 focus:opacity-75 transition p-4 flex items-center justify-between mb-5 no-underline"
                          download
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <p>{item.download_title}</p>
                          <img
                            className="ml-2"
                            src={icon}
                            alt="Download icon"
                          />
                        </a>
                      )
                    })}
                  </div>
                </div>
              )}
              {patientContent.secondary_text.html && (
                <div className="lg:w-1/2 lg:px-2 rte flex-order-1 lg:flex-order-2">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: patientContent.secondary_text.html,
                    }}
                  />

                  {/* <p>
                  And to help our littlest patients we have developed a book just
                  for them. ‘Ra and Poppy’s Visit to Forté Health’ helps children
                  understand what to expect when they are coming to hospital by
                  explaining the hospital journey in a fun and playful way. To
                  download a copy of this book{' '}
                  <a href={download} className="text-charcoal" download>
                    click here
                  </a>
                  .
                </p> */}
                </div>
              )}
            </div>
          </div>

          <div className="px-3 lg:px-14">
            <div className="lg:flex lg:-mx-2">
              {patientContent.primary_image.url && (
                <div className="lg:w-1/2 -mx-4 lg:mx-0 lg:px-2 mb-2">
                  <div className="ratio ratio-5/8">
                    <img
                      src={patientContent.primary_image.url}
                      alt={patientContent.primary_image.alt}
                    />
                  </div>
                </div>
              )}
              <div className="lg:w-1/2 lg:px-2">
                <div className="lg:-mx-2 lg:flex">
                  <div className="hidden lg:block w-1/4 px-2" />
                  {patientContent.secondary_image.url && (
                    <div className="lg:w-3/4 -mx-4 lg:mx-0 lg:px-2 mb-2">
                      <div className="ratio ratio-5/8">
                        <img
                          src={patientContent.secondary_image.url}
                          alt={patientContent.secondary_image.alt}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            id={
              patientContent?.registration_title
                ? formatId(patientContent.registration_title)
                : ''
            }
            className="mt-12 lg:mt-25 lg:pt-10 mb-20 lg:mb-16 px-3 lg:px-14"
          >
            <div className="lg:flex lg:-mx-2">
              {patientContent.registration_title && (
                <div className="lg:w-1/2 lg:px-2">
                  <h3 className="mb-12 lg:mb-20 lg:w-4/5 text-4xl text-grey font-light leading-tight">
                    {patientContent.registration_title}
                  </h3>
                </div>
              )}
              {patientContent.registration_text.html && (
                <div
                  className="lg:w-1/2 lg:px-2 rte text-lg leading-lg"
                  dangerouslySetInnerHTML={{
                    __html: patientContent.registration_text.html,
                  }}
                />
              )}
            </div>
          </div>

          <div className="mb-24 lg:mb-40 px-3 lg:px-14">
            <div className="lg:flex lg:-mx-2">
              <div className="mb-24 lg:w-1/2 lg:px-2">
                <div className="lg:w-3/4">
                  {patientContent.registration_button.url && (
                    <a
                      href={patientContent.registration_button.url}
                      className="text-charcoal bg-green cursor-pointer outline-none no-underline hover:opacity-75 focus:opacity-75 transition p-4 flex items-center justify-between mb-8 lg:mb-16"
                      download
                    >
                      <p>{patientContent.registration_button_title}</p>
                      <img className="ml-2" src={icon} alt="Download icon" />
                    </a>
                  )}
                  {patientContent.registration_secondary_text.html && (
                    <div
                      className="rte"
                      dangerouslySetInnerHTML={{
                        __html: patientContent.registration_secondary_text.html,
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="lg:w-1/2 lg:px-2 rte">
                {patientContent.registration_secondary_title && (
                  <p className="font-extrabold">
                    {patientContent.registration_secondary_title}
                  </p>
                )}
                {patientContent.registration_list.length && (
                  <div className="md:flex flex-wrap md:-mx-2">
                    {patientContent.registration_list.map((item, i) => {
                      return (
                        <div key={i} className="md:px-2 md:w-1/2 mb-12 lg:mb-0">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.registration_item.html,
                            }}
                          />
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>

          <TextBlock
            title={patientContent.hours_title}
            lead={patientContent.hours_text.html}
          />
          <VideoTextBlock
            title={patientContent.rights_title}
            text={patientContent.rights_text}
            videoUrl={patientContent.video?.url}
          />
          <VideoTextBlock
            title={patientContent.bilingual_title}
            text={patientContent.bilingual_text}
            videoUrl={patientContent.bilingual_video?.url}
          />
        </div>
      </Layout>
    )
  }
}

export const pagePatientsQuery = graphql`
  query {
    prismicPatients {
      data {
        title
        hero {
          desktop_image {
            localFile {
              childImageSharp {
                id
              }
            }
            url
          }
          mobile_image {
            localFile {
              childImageSharp {
                id
              }
            }
            url
          }
        }
        lead_title
        lead_text {
          html
        }
        downloads {
          download_title
          download_button {
            url
          }
        }
        secondary_text {
          html
        }
        primary_image {
          url
          alt
          localFile {
            childImageSharp {
              id
            }
          }
        }
        secondary_image {
          url
          alt
          localFile {
            childImageSharp {
              id
            }
          }
        }
        registration_title
        registration_text {
          html
        }
        registration_button_title
        registration_button {
          url
        }
        registration_secondary_title
        registration_secondary_text {
          html
        }
        registration_list {
          registration_item {
            html
          }
        }
        hours_title
        hours_text {
          html
        }
        rights_title
        rights_text {
          html
        }
        video {
          url
        }
        bilingual_title
        bilingual_text {
          html
        }
        bilingual_video {
          url
        }
      }
    }
  }
`

export default PatientsPage
